import React, { useState } from 'react'
// import useLocalStorage from 'use-local-storage'
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Experience from './components/experience/Experience'
import Services from './components/services/Services'
import Sub_Services from './components/sub_services/Sub_Services'
import Testimonials from './components/tesimonials/Testimonials'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import Themes from './components/themes/Themes'
import { PDFViewer } from '@react-pdf/renderer'
import PDF from './components/pdf/PDF' // Import the PDF component

const App = () => {
  return (
    <div>
      <>
        {/* <Themes /> */}
        <Header />
        <Nav />
        <About />
        {/* <Experience /> */}
        <Services />
        <Sub_Services />
        <Testimonials />
        <Contact />
        <Footer />
        
        {/* PDF Viewer */}
        {/* <div style={{ height: '600px', marginTop: '20px' }}>
          <PDFViewer width="100%" height="100%">
            <PDF />
          </PDFViewer>
        </div> */}
      </>
    </div>
  )
}

export default App
