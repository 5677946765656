import React from 'react';
import './nav.css';
import { AiFillHome } from 'react-icons/ai';
import { AiFillInfoCircle } from 'react-icons/ai';
import { AiFillTool } from 'react-icons/ai';
import { AiFillContacts } from 'react-icons/ai';
import { ImPowerCord } from 'react-icons/im';
import { useState } from 'react';

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#');

  return (
    <nav>
      <a href='#' onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''} title="Home">
        <AiFillHome />
      </a>
      <a href='#about' onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''} title="About">
        <AiFillInfoCircle />
      </a>
      <a href='#services' onClick={() => setActiveNav('#services')} className={activeNav === '#services' ? 'active' : ''} title="Services">
        <AiFillTool />
      </a>
      <a href='#sub_services' onClick={() => setActiveNav('#sub_services')} className={activeNav === '#sub_services' ? 'active' : ''} title="Innovative Solutions">
        <ImPowerCord />
      </a>
      <a href='#contact' onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''} title="Contact">
        <AiFillContacts />
      </a>
    </nav>
  );
};

export default Nav;
