import React from 'react';
import { Page, Text, View, Document, Image, StyleSheet, Link } from '@react-pdf/renderer';
import company_image from '../../assets/logo_uno.png';
import background_image from '../../assets/background.jpg';

// Import images
import nicapp from '../../assets/NICEIC_approved_contractor.png';
import niccps from '../../assets/NICEIC_cps.png';
import chas from '../../assets/CHAS-Accreditation.png';
import ssip from '../../assets/SSIP.png';
import dbs from '../../assets/dbs.jpg';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#CECAB3', // Default background color if image fails to render
  },
  container: {
    padding: 40,
    flexGrow: 1,
    position: 'relative', // Ensure the container stretches with content
  },
  backgroundImageContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    width: '100%',
    height: '120%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backgroundImage: {
    width: '135%', // Keep the original width
    height: '100%',
    objectFit: 'cover',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 80,
  },
  image: {
    maxWidth: '100%',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 16,
    backgroundColor: 'rgba(210, 181, 58, 0.8)', // Adjust background color if needed
    padding: 12,
    borderRadius: 8,
    width: '100%',
    maxWidth: '75%',
    alignSelf: 'center',
  },
  textBox: {
    backgroundColor: 'rgba(210, 181, 58, 0.8)', // Adjust background color if needed
    padding: 24,
    borderRadius: 16,
    width: '100%',
    maxWidth: '80%', // Adjust width as needed
    alignSelf: 'center',
    marginBottom: 24, // Increased margin for spacing
  },
  text: {
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 8,
    color: '#000', // Adjust text color if needed
  },
  contactSection: {
    backgroundColor: 'rgba(210, 181, 58, 0.8)', // Adjust background color if needed
    padding: 12,
    borderRadius: 8,
    width: '100%',
    maxWidth: '80%', // Adjust width as needed
    alignSelf: 'center',
  },
  contactHeading: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 8,
    color: '#000',
  },
  contactButtons: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 8,
  },
  contactButton: {
    backgroundColor: '#1E6E9A',
    color: '#000',
    padding: 8,
    marginHorizontal: 8,
    borderRadius: 8,
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    width: 120,
    textDecoration: 'underline', // Underline to resemble links
  },
  footer: {
    backgroundColor: 'rgba(30, 110, 154, 0.8)', // Adjust background color if needed
    padding: 8,
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  footerLogo: {
    fontSize: 12,
    color: '#CECAB3',
    marginBottom: 4,
  },
  footerAddress: {
    fontSize: 10,
    color: '#CECAB3',
    textAlign: 'center',
    marginBottom: 8,
  },
  footerImages: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
  },
  footerImage: {
    width: 75,
    height: 'auto',
    marginHorizontal: 4,
  },
  footerCopyright: {
    fontSize: 8,
    color: '#CECAB3',
    textAlign: 'center',
  },
  secondPageContainer: {
    padding: 40,
    flexGrow: 1,
    position: 'relative', // Ensure the container stretches with content
  },
  secondPageHeading: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 16,
    backgroundColor: 'rgba(210, 181, 58, 0.8)', // Adjust background color if needed
    padding: 12,
    borderRadius: 8,
    width: '100%',
    maxWidth: '75%',
    alignSelf: 'center',
  },
  secondPageSubHeading: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 16,
    backgroundColor: 'rgba(210, 181, 58, 0.8)', // Adjust background color if needed
    padding: 8,
    borderRadius: 8,
    width: '100%',
    maxWidth: '90%',
    alignSelf: 'center',
  },
  textBoxesContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 24, // Increased margin for spacing
  },
  sideTextBox: {
    backgroundColor: 'rgba(210, 181, 58, 0.8)', // Adjust background color if needed
    padding: 16,
    borderRadius: 8,
    width: '48%',
  },
  bulletPoint: {
    fontSize: 12,
    marginBottom: 4,
  },
});

const PDF = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      {/* First Page Content */}
      <View style={styles.container}>
        <View style={styles.backgroundImageContainer}>
          <Image src={background_image} style={styles.backgroundImage} />
        </View>

        <View style={styles.imageContainer}>
          <Image src={company_image} style={styles.image} />
        </View>
        <Text style={styles.title}>M B ELECTRICAL SERVICES</Text>

        {/* Main Text Box */}
        <View style={styles.textBox}>
          <Text style={styles.text}>
            Welcome to MBES
          </Text>
          <Text style={styles.text}>
            Your trusted partner in electrical services. Founded by Michael Baines, we are a dedicated family-run business with over 25 years of experience. We specialize in high-grade electrical installations, compliance testing, maintenance, and innovative design services. Our commitment to quality, customer satisfaction, and industry standards ensures that every project exceeds expectations.
          </Text>
          <Text style={styles.text}>
            Check out our services or get in touch to learn more about how MBES can meet your electrical needs with integrity, reliability, and excellence.
          </Text>
        </View>

        {/* Contact Us Section */}
        <View style={styles.contactSection}>
          <Text style={styles.contactHeading}>Contact Us</Text>
          <View style={styles.contactButtons}>
            <Link src="https://api.whatsapp.com/send?phone=07484353854" style={styles.contactButton}>
              Whatsapp
            </Link>
            <Link src="mailto:enquiries@mbelectricalservices.com" style={styles.contactButton}>
              Email
            </Link>
            <Link src="https://www.mbelectricalservices.com" style={styles.contactButton}>
              Website
            </Link>
          </View>
        </View>

      </View>

      {/* Footer */}
      <View style={styles.footer}>
        <Text style={styles.footerLogo}></Text>
        <View style={styles.footerImages}>
          <Image src={nicapp} style={styles.footerImage} />
          <Image src={niccps} style={styles.footerImage} />
          <Image src={chas} style={styles.footerImage} />
          <Image src={ssip} style={styles.footerImage} />
          <Image src={dbs} style={styles.footerImage} />
        </View>
        <Text style={styles.footerCopyright}>
          Copyright &copy; 2024 M B ELECTRICAL SERVICES. All Rights Reserved.
        </Text>
      </View>
    </Page>

{/* Second Page */}
<Page size="A4" style={styles.page}>
  <View style={styles.container}>
    <View style={styles.backgroundImageContainer}>
      <Image src={background_image} style={styles.backgroundImage} />
    </View>
    <Text style={styles.secondPageHeading}>Our Services</Text>
    <View style={styles.secondPageSubHeading}>
      <Text>Here at MB Electrical Services, we offer a wide range of electrical services for commercial, domestic, and educational clients. Our skilled and insured electricians handle projects of any size, providing installations, compliance testing, maintenance, innovative design solutions and more. We guarantee quality with products from reputable brands and a 12-month warranty on non-consumables.</Text>
      <Text style={{ marginTop: 12 }}>Our core principles of honesty, trust, and reliability drive us to deliver exceptional value at competitive rates and we can tailor our services to meet your specific needs.</Text>
      <Text style={{ marginTop: 12 }}>Contact us for a free consultation to discuss your goals and how our services can benefit you.</Text>
    </View>
    <View style={styles.textBoxesContainer}>

          <View style={styles.sideTextBox}>
            <Text style={styles.bulletPoint}>• Electrical installations</Text>
            <Text style={styles.bulletPoint}>• Compliance testing</Text>
            <Text style={styles.bulletPoint}>• Maintenance services</Text>
            <Text style={styles.bulletPoint}>• Innovative design solutions</Text>
            <Text style={styles.bulletPoint}>• Thermal imaging</Text>
            <Text style={styles.bulletPoint}>• Data point installation</Text>
          </View>
          <View style={styles.sideTextBox}>
            <Text style={styles.bulletPoint}>• EV Charging equipment</Text>
            <Text style={styles.bulletPoint}>• Power and energy logging (PEL)</Text>
            <Text style={styles.bulletPoint}>• Safety and reliability</Text>
            <Text style={styles.bulletPoint}>• Facilities management</Text>
            <Text style={styles.bulletPoint}>• Construction</Text>
            <Text style={styles.bulletPoint}>• EV Workplace scheme</Text>
          </View>
        </View>
      </View>
                  {/* Footer */}
                  <View style={styles.footer}>
        <Text style={styles.footerLogo}></Text>
        <View style={styles.footerImages}>
          <Image src={nicapp} style={styles.footerImage} />
          <Image src={niccps} style={styles.footerImage} />
          <Image src={chas} style={styles.footerImage} />
          <Image src={ssip} style={styles.footerImage} />
          <Image src={dbs} style={styles.footerImage} />
        </View>
        <Text style={styles.footerCopyright}>
          Copyright &copy; 2024 M B ELECTRICAL SERVICES. All Rights Reserved.
        </Text>
      </View>
    </Page>
  </Document>
);

export default PDF;
