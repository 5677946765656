import React from 'react'
import { FaPhone } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { FaWhatsappSquare } from "react-icons/fa";
import {useState} from 'react'

const HeaderSocials = () => {
  const [activeNav, setActiveNav] = useState('#')
  return (
    <div className='header_socials'>
        <a href='mailto:enquiries@mbelectricalservices.com' target='_blank'><IoIosMail/></a>
        <a href='https://api.whatsapp.com/send?phone=07484353854' target='_blank'><FaWhatsappSquare/></a>
        <a href='#contact'onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><FaPhone/></a>
    </div>
  )
}

export default HeaderSocials