import React, { useState } from 'react';
import './header.css';
import company_image from '../../assets/logo_uno.png';
import company_image1 from '../../assets/logo_dos.png';
import company_image2 from '../../assets/logo_tres.png';
import CTA from './CTA';
import HeaderSocial from './HeaderSocials';

const images = [company_image];

const Header = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) => 
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => 
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <header>
      <div className='container header_container'>
        <h2 className='text-light'>Welcome to</h2>
        <div className='mbes'>
          <img src={images[currentImageIndex]} alt='MBES' />
        </div>
        {/* <div className='image-switcher'>
          <button onClick={handlePrevious}>Previous logo</button>
          <button onClick={handleNext}>Next logo</button>
        </div> */}
        <h3 className='text-light'><strong><span style={{ fontSize: '22px' }}>M B ELECTRICAL SERVICES</span></strong></h3>
        <h4 className='text-light'>Your Trusted Electrical Service Provider – Specialising in Installations, Compliance, Maintenance, and Design with Over 25 Years of Experience.</h4>
        <div className='text-box'>
          <p className='text-light'>
            <strong><span style={{ fontSize: '22px' }}>MBES, </span></strong>
            established by Michael Baines, is a trusted, family-run business offering high grade electrical installations, compliance testing, maintenance, design services and more. With over 25 years of industry experience, we prioritise quality, customer satisfaction, and adherence to industry standards. Our commitment to honesty, reliability, and excellence, along with being fully insured, ensures we meet and exceed client expectations in every project.
          </p>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <CTA />
        <HeaderSocial />
        <a href='#contact' className='scroll_down'>Scroll Down</a>
      </div>
    </header>
  );
}

export default Header;
