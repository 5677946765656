import React from 'react';
import './services.css';
import { BiPhone, BiCheck, BiWrench, BiShield, BiPower, BiThermometer, BiPencil, BiData } from 'react-icons/bi';
import { FaBolt, FaTemperatureHigh } from 'react-icons/fa'; // Importing icons from FontAwesome
import { GiDrill } from "react-icons/gi";
import { LuClipboardCheck } from "react-icons/lu";
import { TbCircuitSwitchOpen } from "react-icons/tb";
import { FaComputer } from "react-icons/fa6";

const Services = () => {
  return (
    <section id='services'>
      <h5>What we can do:</h5>
      <h2>Services</h2>

      <div className='text-box'>
        <p><strong><span style={{ fontSize: '22px' }}>MBES </span></strong>
          offers a seamless electrical service from initial concept to job completion, backed by over 25 years of industry experience. Every phase of our projects is managed with efficiency, diligence, and a commitment to excellence. We provide comprehensive design, installation, and maintenance for your electrical systems, along with tailored after-sales support.
        </p>
        <br />
        <p>
          For a full list of our services, please see below. To discuss your specific requirements, feel free to contact us - we are here to assist you.
        </p>
      </div>
      <br /><br />
      <div className='container services_container'>
        <article className='service'>
          <div className='service_head'>
            <GiDrill className='service_icon' size={48} />
            <h3 style={{ fontWeight: 'bold' }}>MB-INSTALL</h3>
            <h3>Electrical Installations</h3>
          </div>
          <div className='service_list'>
            <p><strong><span style={{ fontSize: '16px' }}>MBES </span></strong> provides top-tier electrical installations for a variety of sectors.</p>
            <p>Our team of experienced professionals ensures that every installation is completed with precision and adheres to the highest safety standards.</p>
            <p>We handle all aspects of electrical installations, from initial planning and design to the final implementation, ensuring a seamless process that meets your specific requirements.</p>
          </div>
        </article>
        {/* END OF Electrical Installations - MB-INSTALL */}

        <article className='service'>
          <div className='service_head'>
            <LuClipboardCheck className='service_icon' size={48} />
            <h3 style={{ fontWeight: 'bold' }}>MB-COMPLIANCE</h3>
            <h3>Electrical Compliance</h3>
          </div>
          <div className='service_list'>
            <p>Electrical compliance is crucial for the safe operation of any installation. <strong><span style={{ fontSize: '16px' }}>MBES </span></strong> has a proven track record in inspection, testing, and reporting across various properties.</p>
            <p>We ensure your systems meet regulatory standards and offer tailored testing programs to minimize disruption.</p>
            <p>As an NICEIC approved contractor, our reports are of the highest standard.</p>
            <p><BiPhone className='service_list-icon' /> Contact us for compliance queries or to discuss your needs.</p>
          </div>
        </article>
        {/* END OF Electrical Compliance - MB-COMPLIANCE */}

        <article className='service'>
          <div className='service_head'>
            <BiWrench className='service_icon' size={48} />
            <h3 style={{ fontWeight: 'bold' }}>MB-MAINTAIN</h3>
            <h3>Property Maintenance</h3>
          </div>
          <div className='service_list'>
            <p>Regular maintenance of your electrical systems is key to preventing breakdowns and ensuring operational efficiency.</p>
            <p><strong><span style={{ fontSize: '16px' }}>MBES </span></strong> offers both planned preventative maintenance (PPM) and reactive call-outs, either through service level agreements (SLAs) or as needed.</p>
            <p><BiPhone className='service_list-icon' /> Contact us to discuss a maintenance plan tailored to your business.</p>
          </div>
        </article>
        {/* END OF Property Maintenance - MB-MAINTAIN */}

        <article className='service'>
          <div className='service_head'>
            <FaTemperatureHigh className='service_icon' size={48} />
            <h3 style={{ fontWeight: 'bold' }}>MB-THERMAL</h3>
            <h3>Thermal Imaging</h3>
          </div>
          <div className='service_list'>
            <p>Thermal imaging is a non-invasive diagnostic technique that uses infrared technology to detect surface heat variations. It helps identify issues such as loose connections and load imbalances before they lead to failures.</p>
            <p>This service is crucial for ensuring the reliability and safety of your electrical systems.</p>
            <p><BiPhone className='service_list-icon' /> Contact <strong><span style={{ fontSize: '16px' }}>MBES </span></strong> for more information on thermal imaging services.</p>
          </div>
        </article>
        {/* END OF Thermal Imaging - MB-THERMAL */}

        <article className='service'>
          <div className='service_head'>
            <TbCircuitSwitchOpen className='service_icon' size={48} />
            <h3 style={{ fontWeight: 'bold' }}>MB-DESIGN</h3>
            <h3>Electrical Design</h3>
          </div>
          <div className='service_list'>
            <p>Proper electrical design is essential for the safety and efficiency of any installation. <strong><span style={{ fontSize: '16px' }}>MBES </span></strong> offers comprehensive design services to ensure your systems are compliant and reliable.</p>
            <p>We use bespoke design software, trusted by leading businesses, to provide accurate design and certification.</p>
            <p><BiPhone className='service_list-icon' /> Contact us to discuss your electrical design needs.</p>
          </div>
        </article>
        {/* END OF Electrical Design - MB-DESIGN */}

        <article className='service'>
          <div className='service_head'>
            <FaComputer className='service_icon' size={48} />
            <h3 style={{ fontWeight: 'bold' }}>MB-DATA</h3>
            <h3>Data/Telecoms</h3>
          </div>
          <div className='service_list'>
            <p>In the era of high data consumption, reliable and secure data connections are essential. While WiFi is convenient, hardwiring offers superior performance and security, especially in buildings with concrete walls and floors.</p>
            <p><strong><span style={{ fontSize: '16px' }}>MBES </span></strong> provides expert data point installation to enhance your network’s reliability.</p>
            <p><BiPhone className='service_list-icon' /> Contact us to discuss your data and telecom needs.</p>
          </div>
        </article>
        {/* END OF Data/Telecoms - MB-DATA */}
      </div>
    </section>
  );
}

export default Services;
