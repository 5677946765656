import React from 'react';
import './sub_services.css';
import { BiPhone, BiCheck, BiCar, BiBattery, BiBuildingHouse } from 'react-icons/bi';
import { MdElectricCar } from "react-icons/md";
import { PiEngineBold } from "react-icons/pi";

const data = [
  {
    id: 1,
    icon: <MdElectricCar size={48} />,
    title: (
      <span>
        <strong>
          <span style={{ fontSize: '22px' }}>MB-EV</span>
        </strong>{' '}
        <br />
        <span style={{ textDecoration: 'underline' }}>
          Electric Vehicle Charging Equipment
        </span>
      </span>
    ),
    description: (
      <React.Fragment>
        <p>We install electric vehicle charging points at your home or business, offering efficient and reliable setups tailored to your needs.*</p>
        <br />
        <p>
          <strong>MBES</strong> is an OZEV Approved installer for electric vehicle charging points.
        </p>
        <br />
        <p>
          <strong>Workplace Scheme:</strong> We can assist in applying for a grant of up to £14,000 to install EV Charging Points at your workplace under the Workplace Charging Scheme. This grant covers up to £350 per charging socket, with a maximum of 40 sockets. Our team will survey your property to determine the optimal solution for your needs.
        </p>
        <br />
        <p>To discuss an EV charging installation at your home or business, please get in touch.</p>
        <br />
        <p>
          <strong>*Subject to stringent pre-installation requirements.</strong>
        </p>
      </React.Fragment>
    ),
  },
  {
    id: 2,
    icon: <PiEngineBold size={48} />,
    title: (
      <span>
        <strong>
          <span style={{ fontSize: '22px' }}>MB-POWER</span>
        </strong>{' '}
        <br />
        <span style={{ textDecoration: 'underline' }}>
          Power, Energy and Hybrid Power
        </span>
      </span>
    ),
    description: (
      <React.Fragment>
        <p>Efficient energy use and innovative power solutions can significantly reduce operating costs. Regular monitoring and analysis of consumption can highlight issues, but power and energy loggers provide precise measurements to pinpoint energy wastage.</p>
        <br />
        <p>MBES offers comprehensive power and energy logging (PEL) services to identify and remedy areas of energy inefficiency. With data collected during a PEL session, we can address key areas of concern and implement solutions to reduce energy waste.</p>
        <br />
        <p>Given the importance of reducing carbon footprints, investing in a PEL session can make a substantial difference in your energy efficiency.</p>
        <br />
        <p><BiPhone /> Contact us to organize a PEL session or to learn more about our hybrid power solutions.</p>
      </React.Fragment>
    ),
  },
  {
    id: 3,
    icon: <BiBuildingHouse size={48} />,
    title: (
      <span>
        <strong>
          <span style={{ fontSize: '22px' }}>MB-FM</span>
        </strong>{' '}
        <br />
        <span style={{ textDecoration: 'underline' }}>
          Non-Electrical Services
        </span>
      </span>
    ),
    description: (
      <React.Fragment>
        <p>In collaboration with our trusted partner, <strong><span style={{ fontSize: '16px' }}>Safety Check UK Ltd</span></strong>, we offer a range of non-electrical services, including:</p>
        <br />
        <ul>
          <li><BiCheck /> Fire Safety</li>
          <li><BiCheck /> Plumbing</li>
          <li><BiCheck /> Gas</li>
          <li><BiCheck /> Construction</li>
          <li><BiCheck /> Plastering</li>
          <li><BiCheck /> Plant Hire</li>
          <li><BiCheck /> Asbestos Removal</li>
          <li>and more...</li>
        </ul>
        <br />
        <p>For more information or to discuss your specific requirements, please contact us. We are here to assist you with all your needs.</p>
      </React.Fragment>
    ),
  },
];

const Sub_Services = () => {
  return (
    <section id='sub_services'>
      <h5>What else we can do:</h5>
      <h2>Innovative Solutions</h2>

      {/* Add the text box here */}
      <div className='text-box'>
        <p>
          At <strong>
            <span style={{ fontSize: '22px' }}>MBES, </span>
          </strong> we provide cutting-edge solutions designed to meet the evolving needs of modern businesses and homes. Our range of innovative services ensures efficiency, reliability, and sustainability in all your electrical and related projects.
        </p>
      </div>
      <br />
      <br />

      <div className='container portfolio_container'>
        {data.map(({ id, icon, title, description }) => {
          return (
            <article key={id} className='portfolio_item'>
              <div className='portfolio_item-icon'>
                {icon}
              </div>
              <h3>{title}</h3>
              <p className='portfolio_item-description'>{description}</p>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Sub_Services;
