import React from 'react'
import './about.css'
import US from '../../assets/about_us.png'
import {FaAward} from 'react-icons/fa'
import {AiOutlineUserAdd} from 'react-icons/ai'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
<section id='about'>
  <h5>Get To Know...</h5>
  <h2>MBES</h2>

  <div className='container about_container'>
    <div className='about_us'>
      <div className='about_us-image'>
        <img src={US} alt='About Image' />
      </div>
    </div>

    <div className='about_cards'>
      <article className='about_card'>
        <FaAward className='about_icon' />
        <h5>Experience</h5>
        <small>25+ Years</small>
      </article>

      <article className='about_card'>
        <AiOutlineUserAdd className='about_icon' />
        <h5>Partnerships</h5>
        <small>10+ Worldwide</small>
      </article>

      <article className='about_card'>
        <VscFolderLibrary className='about_icon' />
        <h5>Projects</h5>
        <small>100+ Completed</small>
      </article>
    </div>

    <div className='about_content p'>
      <p style={{ textAlign: 'center', display: 'block', width: '95vw', margin: '0 auto', padding: '0 50px' }}>
      <strong><span style={{ fontSize: '22px' }}>MBES</span></strong> excels in delivering high-quality electrical installations, compliance testing, ongoing maintenance, and innovative design services. Our commitment to quality, customer satisfaction, and adherence to industry standards ensures that we consistently meet and exceed the expectations of our clients. As a trusted electrical service provider, we pride ourselves on our honesty, reliability, and dedication to excellence in every project we undertake.</p>
      <br></br>
      <p style={{ textAlign: 'center', display: 'block', width: '95vw', margin: '0 auto', padding: '0 50px' }}>
      <strong><span style={{ fontSize: '16px' }}>Quality:</span></strong> We carefully select our suppliers to ensure our customers receive only genuine products from reputable brands and all of our non consumables come with a 12 month warranty. This focus on quality ensures we consistently meet the expectations and individual requirements of our customers.</p>
      <br></br>
      <p style={{ textAlign: 'center', display: 'block', width: '95vw', margin: '0 auto', padding: '0 50px' }}>
      <strong><span style={{ fontSize: '16px' }}>Principles:</span></strong> We operate a customer-first oriented business, with honesty, trust, and reliability at the forefront of our business ethic. Our customers are treated with the utmost respect, and we always uphold our promises.</p>
      <br></br>
      <p style={{ textAlign: 'center', display: 'block', width: '95vw', margin: '0 auto', padding: '0 50px' }}>
      <strong><span style={{ fontSize: '16px' }}>Industry Standards:</span></strong> We take the responsibility of meeting industry standards very seriously. To this end, we ensure that our training, qualifications, accreditations, and continuous professional development (CPD) are regularly maintained and upheld.
      </p>
      <br></br>
      <p style={{ textAlign: 'center', display: 'block', width: '95vw', margin: '0 auto', padding: '0 50px' }}>
      <strong><span style={{ fontSize: '16px' }}>Value:</span></strong> We believe the services we provide are competitively priced and offer excellent value for money. Our comprehensive overall service ensures repeat business from our existing client-base whilst maintaining healthy business relationships.
      </p>
    </div>
  </div>
</section>


  )
}

export default About