import React from 'react';
import './footer.css';
import company_image from '../../assets/logo_uno.png'


// Import images
import nicapp from '../../assets/NICEIC_approved_contractor.png';
import niccps from '../../assets/NICEIC_cps.png';
import chas from '../../assets/CHAS-Accreditation.png';
import ssip from '../../assets/SSIP.png';

const Footer = () => {
  return (
    <footer className="main-footer"> {/* Added the "main-footer" class */}
      {/* <ul className='permalinks'>
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#services'>Services</a></li>
        <li><a href='#contact'>Contact</a></li>
      </ul>
      <br /> */}
      <a href='#' className='mbes_footer'>
          <img src={company_image} alt='MBES' />
        </a>
      <a href='#' className='footer_logo'>M B ELECTRICAL SERVICES</a>
      <p className='permalinks'>
      10 Sidlaw Close<br />
      Oldham<br />
      Manchester<br />
      OL8 3WQ<br />
      </p>
      {/* <p className='permalinks'>Company Number: 12345678</p> */}

      {/* Add image section here */}
      <div className='footer-images'>
        <img src={nicapp} alt="Approved Contractor" />
        <img src={niccps} alt="Competent Person Scheme" />
        <img src={chas} alt="CHAS Accredited" />
      </div>
      <br />
      <br />
      <p className='permalinks_two'>
        Copyright &copy; 2024 M B ELECTRICAL SERVICES. All Rights Reserved.
      </p>
      <a href='https://evolv.org.uk/' target='_blank' className='permalinks_two'>
        <strong><span>Website built by: eVOLV</span></strong>
      </a>
      <br></br>
      <br></br>
    </footer>
  );
}


export default Footer;
