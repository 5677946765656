
import React from 'react'

const CTA = () => {
  const handleClick = () => {
    // Construct the download URL
    const pdfURL = `${window.location.origin}/brochure.pdf`;

    // Simulate a click on a temporary anchor tag
    const link = document.createElement('a');
    link.href = pdfURL;
    link.download = 'brochure.pdf'; // Set the download filename
    link.click();
  }

  return (
    <div className='cta'>
        <button onClick={handleClick} className='btn btn-primary'>Download Brochure</button>
        <a href='#contact' className='btn btn-primary'>Let's Talk</a>
    </div>
  )
}

export default CTA
