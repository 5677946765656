import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/avatar1.png'
import AVTR2 from '../../assets/avatar2.png'
import AVTR3 from '../../assets/avatar3.png'
import AVTR4 from '../../assets/avatar4.png'

// import Swiper modules
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data = [
  {
    avatar: AVTR1,
    name: 'Charlotte',
    review: 'I have no hesitation at all in recommending M B Electrical Services! From start to finish Mike was professional and reliable, its very clear that he takes prides in his work and that reflects in everything he does.If you are looking for an honest and reliable Electrician, here he is!'
  },
  {
    avatar: AVTR1,
    name: 'Catherine',
    review: 'I have no hesitation in recommending Mike, if you require a knowledgeable, professional and very accommodating electrician.  On the strength of his existing Google reviews I called Mike out in an emergency, when all the power was cut to my sons flat by another tradesman sorting out a shower installation.  Mike came out within 30mins of my phone call. He sourced the relevant parts needed to rectify the issues and tested and checked all the necessary electrics so the power could be restored at the earliest possible time.  Mike filled me with confidence when he turned up with all his testing equipment, so much so that he will be carrying out some further electrical installations at the property. He will definitely be my go to electrician for future jobs.'
  },
  {
    avatar: AVTR2,
    name: 'Tobias',
    review: 'Asked Mike to come round and quote up for additional plug sockets and a new consumer unit. He went through the whole process prior to work, made sure we had all the information required to make a fully-formed decision. Even checked in to see if we thought his pricing was fair, which after having multiple quotes he certainly was. He was really responsive during the whole process, and his work was great. One of our main criteria was being able to trust somebody in the house with our cats during the day as we were unable to work from home. Mike would check in during the day letting us know the cats were fine (and fed) and any issues he had multiple solutions and recommendations to solve. I also love that he’ll tell you straight up if something isn’t safe, opposed to others who might just do a quick fix. Fantastic work, and really great from start to finish.'
  },
  {
    avatar: AVTR4,
    name: 'Shaun',
    review: 'Mike is very Professional, in everything he does from quotations to doing our jobs, he is clean and tidy and very friendly and always does a good job, I would recommend him very highly. Shaun (Site Manager, Ss Aidan&Oswald Primary School)'
  }
]

const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5><a href="https://www.google.com/maps/place/M+B+Electrical+Services+(MBES)/@53.5235633,-2.1141244,17z/data=!4m8!3m7!1s0x4a58237e93f45c35:0x8904e0aea8406cd7!8m2!3d53.5235633!4d-2.1141244!9m1!1b1!16s%2Fg%2F11rstlsbzr?entry=ttu" target="_blank" rel="noopener noreferrer" className="google-link">Google</a> reviews from clients</h5>
      <h2>Testimonials</h2>

      <Swiper className='container testimonials_container'
      // install Swiper modules
      modules={[Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}>
        {
          data.map(({avatar, name, review}, index) => {
            return (
              <SwiperSlide key={index} className='testimonial'>
              <div className='client_avatar'>
                <img src={avatar} alt={`${name}'s avatar`} />
              </div>
              <h5 className='client_name'>{name}</h5>
              <small className='client_review'>{review}</small>
            </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials
