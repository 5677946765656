import React from 'react'
import './contact.css'
import { MdOutlineEmail } from 'react-icons/md'
import { AiOutlineLinkedin } from 'react-icons/ai'
import { BsWhatsapp } from 'react-icons/bs'
import { FaPhone } from "react-icons/fa";

const Contact = () => {
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Text copied to clipboard');
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact</h2>
      <div className='container contact_container'>
        <div className='contact_options'>
          <article className='contact_option'>
            <FaPhone className='contact_option-icon' />
            <h4>Call Us</h4>
            <h5>(0161) 399 3854</h5>
          </article>
          <article className='contact_option'>
            <MdOutlineEmail className='contact_option-icon' />
            <h4>Email</h4>
            <h5></h5>
            <a href='mailto:enquiries@mbelectricalservices.com' target='_blank'>enquiries@mbelectricalservices.com</a>
          </article>
          <article className='contact_option'>
            <BsWhatsapp className='contact_option-icon' />
            <h4>WhatsApp / Mobile</h4>
            <h5>07484353854</h5>
            <a href='https://api.whatsapp.com/send?phone=07484353854' target='_blank'>Send a message</a>
          </article>
        </div>
      </div>
    </section>
  )
}

export default Contact